import React from 'react'
import { Link } from 'gatsby'

export const MeetUsMenu = (props) => (
  <div {...props}>
    <div className="flex flex-col gap-y-3 justify-center text-xl lg:text-2xl text-primary-2">
      <Link to="/meet-us/" activeClassName="text-primary-3 text-shadow">Sofia</Link>
      <Link to="/meet-us/palo-alto/" activeClassName="text-primary-3 text-shadow">Palo Alto</Link>
      <Link to="/meet-us/events/" activeClassName="text-primary-3 text-shadow">Events</Link>
      <Link to="/meet-us/feed/" activeClassName="text-primary-3 text-shadow">Feed</Link>
    </div>
  </div>
)
